$lightest-gray: #F2F2F2;
$primary-50: #91CFFF;
$primary-75: #40ABFF;
$sub-body: #8A8A8A;
$secondary-75: #33CAFF;
$light-gray: #D9D9D9;
$light-gray-5: #CCCCCC;
$light-blue-15: #99AECF;
$gray-20: #E6E6E6;
$table-border: rgba(230, 230, 230, 0.3);
$white: #ffffff;
$black: #000000;
$danger: #FF3640;
