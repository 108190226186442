// Fix aspect ratio not working in Safari < 14
.aspect-square {
  aspect-ratio: 1/1;

  @supports not (aspect-ratio: 1 / 1) {
    position: relative;
    &::before {
      display: block;
      content: "";
      width: 100%;
      padding-top: (1 / 1) * 100%;
    }

    > * {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }
}
