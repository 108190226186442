@import 'variables.scss';
@import 'unsupport-old-browser';
@import 'fonts';

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Spoqa Han Sans Neo', 'sans-serif';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  line-height: 1;
  color: #3F3E4E;

  &.open-modal {
    overflow: hidden;
  }
}

.gz-text-txsm {
  font-size: 10px;
  line-height: 13px;
}

.gz-text-xtiny {
  font-size: 11px;
  line-height: 20px;
}

.gz-text-tiny {
  font-size: 12px;
  line-height: 16px;
}

.gz-text-xsm {
  font-size: 13px;
  line-height: 16px;
}

.gz-text-sm {
  font-size: 14px;
  line-height: 16px;
}

.gz-text-xs {
  font-size: 15px;
  line-height: 20px; 
}

.gz-text-base {
  font-size: 16px;
  line-height: 1;
}

.gz-text-md {
  font-size: 17px;
  line-height: 25px;
}

.gz-text-lg {
  font-size: 18px;
  line-height: 24px; 
}

.gz-text-xl {
  font-size: 20px;
  line-height: 24px;
}

.gz-text-2xl {
  font-size: 24px;
  line-height: 1;
}

.gz-text-3xl {
  font-size: 32px;
  line-height: 1.2;
}

.gz-text-4xl {
  font-size: 40px;
  line-height: 1.5;
}

.latin-font {
  font-family: -apple-system, BlinkMacSystemFont, 'Lato', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-size: 16px;
  line-height: 20px;

  .gz-text-txsm {
    font-size: 10px;
    line-height: 13px;
  }

  .gz-text-xtiny {
    font-size: 11px;
    line-height: 20px;
  }

  .gz-text-tiny {
    font-size: 12px;
    line-height: 16px;
  }
  
  .gz-text-xsm {
    font-size: 13px;
    line-height: 16px;
  }
  
  .gz-text-sm {
    font-size: 14px;
    line-height: 16px;
  }
  
  .gz-text-xs {
    font-size: 15px;
    line-height: 20px;
  }
  
  .gz-text-base {
    font-size: 16px;
    line-height: 20px;
  }
  
  .gz-text-md {
    font-size: 17px;
    line-height: 20px;
  }
  
  .gz-text-lg {
    font-size: 18px;
    line-height: 24px;
  }
  
  .gz-text-xl {
    font-size: 20px;
    line-height: 24px;
  }
  
  .gz-text-2xl {
    font-size: 24px;
    line-height: 36px;
  }
  
  .gz-text-3xl {
    font-size: 32px;
    line-height: 48px;
  }
  
  .gz-text-4xl {
    font-size: 40px;
    line-height: 48px;
  }
}
  
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1{
  text-align:center;
  margin-bottom:50px;
  margin-top:50px;
}

.fa {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
  color: #3C4857;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

input {
  border:0;
  outline:0;
}

input:focus {
  outline:none!important;
}

input:-webkit-autofill {
  background-color: transparent !important;
}

// Remove forcus:
.outline-visible:focus-visible{
  outline: none;
}

// Hide default hide/show password button
input[type=password]::-ms-reveal,
input[type=password]::-ms-clear
{
    display: none;
}

// Hide default increase/decrease number button
// Chrome, Safari, Edge, Opera
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

// Firefox
input[type=number] {
  -moz-appearance: textfield;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.direction-rtl {
  direction: rtl;
}

.image-cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
}

.image-contain {
  width: 100%;
  height: 100%;
  object-fit: contain;
  overflow: hidden;
}

.scale-video {
  width: 100%;
  padding-top: 56.25%;
  position: relative;
}

.scale-video__content {
  position: absolute;
  width: 100%;
  z-index: 1;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%,-50%);
  -webkit-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%);
}

.list-default {
  ul{
    list-style: none;
    li {
      margin-bottom: 8px;
      &::before {
        content: "•";
        padding-right: 4px;
      }
      &:last-child{
        margin-bottom: 0;
      }
    }
  }
}

.span-space span {
  &::after{
    content: '|';
    padding: 0 8px;
  }
  &:last-child::after {
    display: none;
  }
}
.span-space--dot span::after {
  content: '•';
  color: #8A8A8A;
}

.gz-divide-x {
    &:not(.gz-divide-no-padding) > :not(:first-child) {
      padding-left: 8px;
    }

    &:not(.gz-divide-no-padding) > :not(:last-child) {
      padding-right: 8px;
    }

    & > :not(:last-child) {
      position: relative;
      &:after {
        content: '';
        position: absolute;
        top:0;
        bottom: 0;
        right: 0;
        margin-top: auto;
        margin-bottom: auto;
        height: 12px;
        width: 1px;
        background: $gray-20;
    }
  }
}

.round-background {
  border-radius: 10px;
  padding: 1px 6px;
  line-height: 1.5;
  min-width: 20px;
  font-size: 12px;
  display: inline-block;
}

.radial-gradient {
  background-image: radial-gradient(#FFDE50 16.67%,#FFEA94 100%);
}

.backgroud-shadow {
  position: relative;
  background-color: white;
  &::before {
    content: '';
    position: absolute;
    top: -8px;
    height: 20px;
    left: 0;
    width: 100%;
    background: radial-gradient(50% 50% at 50% 50%, #D9D9D9 0%, rgba(217, 217, 217, 0) 100%);
    z-index: -2;
  }
  &::after{
    content: '';
    position: absolute;
    bottom: -8px;
    height: 20px;
    left: 0;
    width: 100%;
    background: radial-gradient(50% 50% at 50% 50%, #D9D9D9 0%, rgba(217, 217, 217, 0) 100%);
    transform: rotate(-180deg);
    z-index: -2;
  }
}

@layer utilities {
  .sticky-shadow {
    position: sticky;
    z-index: 10;

    .sticky-shadow__content {
      position: sticky;
      top: 0;
      margin-top: -16px;
    }

    &::before{
      content: '';
      display: block;
      height: 16px;
      position: sticky;
      box-shadow: 0 5px 5px -4px rgba(0, 0, 0, 0.12);
    }
  }
}

// overide datapicker
.react-datepicker__triangle {
  left: -15px !important;
}

.react-date-picker__wrapper {
  border: none;
}

.react-date-picker__inputGroup__input:invalid {
  background: transparent;
}

.react-date-picker__inputGroup {
  padding: 0;
}

.text-truncate-inner span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-truncate-2 {
  /* autoprefixer: off */
  -webkit-box-orient: vertical;
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}

// Hidden reCaptcha
.grecaptcha-badge {
  visibility: hidden;
}

// overide Slick slider
.slick-slider {
  .slick-track {
    display: flex;
    align-items: center;
  }
  img {
    display: inline;
  }
  &:not(.slider-custom-dots) {
    .slick-dots {
      bottom: 15px;
      li {
        button {
          height: auto;
          width: auto;
          padding: 0 4px;
          &::before{
            background-color: $light-gray;
            height: 8px;
            width: 8px;
            border-radius: 6px;
            content: '';
          }
        }
        &.slick-active button::before{
          background-color: $secondary-75;
          width: 24px;
        }
      }
    }
  }
}

.ranking-table {
  .slick-track {
    align-items: normal;
  }
}

.slider-custom {
  .slick-slide {
    margin-right: 12px;
  }
}

.slider-custom-8px {
  .slick-slide {
    margin-right: 8px;
  }
}

.magic-dots.slick-dots {
  bottom: -24px;
  padding-bottom: 24px;
  li {
    width: 6px;
    height: 6px;
    margin: 0 3px;

    button {
      width: 6px;
      height: 6px;
      padding: 0;

      &:before {
        color: $light-gray-5;
        opacity: 0.5;
        font-size: 6px;
        width: 6px;
        height: 6px;
        line-height: 6px;
      }
    }

    &.small {
      button {
        width: 6px;
        height: 6px;
  
        &:before {
          font-size: 4px;
          width: 6px;
          height: 6px;
          line-height: 6px;
        }
      }
    }

    &.active {
      button {
        width: 6px;
        height: 6px;
  
        &:before {
          opacity: 1;
          color: $light-blue-15;
        }
      }
    }
  }
}

// Overide Scroll
.scroll-hidden {
  &::-webkit-scrollbar {
    display: none;
    height: 0px;
  }
}

.scrollbar-custom {
  &::-webkit-scrollbar {
    display: block;
    height: 4px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }
      
  &::-webkit-scrollbar-thumb {
    background-color: $primary-50;
    border-radius: 13px;
    border-right: none;
    border-left: none;

    &:hover {
      background-color: $primary-75;
    }
  }

  &::-webkit-scrollbar-track-piece:end {
    border-radius: 13px;
    background-color: $lightest-gray;
    margin-right: 100px; 
  }

  &::-webkit-scrollbar-track-piece:start {
    border-radius: 13px;
    background-color: $lightest-gray;
    margin-left: 100px;
  }
}

@media (min-width: 1024px) {
  .scrollbar-custom {
    &::-webkit-scrollbar {
      height: 8px;
    }
  }

  &::-webkit-scrollbar-track-piece:end {
    margin-right: 150px !important; 
  }

  &::-webkit-scrollbar-track-piece:start {
    margin-left: 150px !important;
  }
}

.text-stroke {
  -webkit-text-stroke: 1px black;
}

.gz-break-words {
  word-break: break-word;
}

.contentEditable[placeholder]:empty:before {
  display: block; /* For Firefox */
  content: attr(placeholder);
  pointer-events: none;
  color: $sub-body;
  height: 100%;
}

.feed-slider {
  .slick-dots {
    position: static !important;
  }
}

.table-score-card {
  td {
    padding-top: 4px;
    padding-bottom: 4px;
    border: 1px solid $table-border;

    &:first-child {
      border-left: none;
    }

    &:last-child {
      border-right: none;
    }
  }

  tbody {
    tr:first-child {
      td {
        border-top: none;
      }
    }

    tr:last-child {
      td {
        border-bottom: none;
      }
    }
  }
}

.left-polygon {
  clip-path: polygon(0% 0%, 80% 0%, 100% 0, 80% 100%, 0% 100%);
}

.right-polygon {
  clip-path: polygon(20% 0, 100% 0%, 100% 100%, 0% 100%);
}

.react-switch-checkbox {
  height: 0;
  width: 0;
  visibility: hidden;
}

.react-switch-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 36px;
  height: 20px;
  border-radius: 10px;
  position: relative;
  transition: background-color .2s;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;
}

.react-switch-label .react-switch-button {
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  width: 16px;
  height: 16px;
  border-radius: 8px;
  transition: 0.2s;
  background: #ffffff;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
}

.react-switch-checkbox:checked + .react-switch-label .react-switch-button {
  left: calc(100% - 2px);
  transform: translateX(-100%);
}

.react-switch-label:active .react-switch-button {
  width: 16px;
}

.border-gradient {
  border: 2px solid transparent;
  background: linear-gradient(135deg, #0066FF,#00CC99) border-box;
}

.ring-animation {
  animation-name: shake;
  animation-timing-function: ease-in-out;
  animation-duration: .5s;
  animation-iteration-count: 2;
  animation-delay: .5s;
}

@keyframes shake {
  0%,
  100% {
    transform: rotate(0deg);
  }
  20%,
  60% {
    transform: rotate(6deg);
  }
  40%,
  80% {
    transform: rotate(-6deg);
  }
}

.icon-scale {
  transform-origin:  top;
  animation: scaleHeight 0.8s ease 0.5s 1;
}

@keyframes scaleHeight {
  0% {
    transform: scale(1,1);
  }
  50% {
    transform: scale(1,1.3);
  }
  100% {
    transform: scale(1,1);
  }  
}

.otpInput {
  width: 52px;
  height: 52px;
  margin: 5px;
  text-align: center;
  border-radius: 8px;
  border: 1px solid $gray-20;
  font-weight: 700,
}
.otpInput:focus {
  border: 1px solid $secondary-75;
}

.otpInputError {
  border: 1px solid $danger;
  color: $danger;
}
.otpInputLoading {
  border: 1px solid $secondary-75;
}

#toolbar.down {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 500ms;
}

.input-mention {
  &.input-mention-mobile {
    margin-left: 8px;
    max-height: 120px;
    overflow-y: auto;

    .input-mention__highlighter {
      max-height: 120px;
    }

    textarea {
      margin-left: 1px !important;
      max-height: 120px;
    }
  }

  &.input-mention-desktop {
    border-radius: 16px;
    padding: 10px 12px;
    margin-left: 16px;

    textarea {
      padding: 10px 12px;
    }
  }

  width: 100%;
  word-break: break-word;

  textarea {
    caret-color: #40ABFF;
    width: 100%;
    outline: none;
  }

  .input-mention__suggestions {
    z-index: 9999 !important;
    width: 300px !important;
    left: 0 !important;
    border-radius: 8px;
    
    .input-mention__suggestions__list {
      border-radius: 8px;
      max-height: 200px;
      overflow-y: auto;

      .input-mention__suggestions__item {
        font-size: 14px;
        
        &:first-child {
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
        }

        &:last-child {
          border-bottom-left-radius: 8px;
          border-bottom-right-radius: 8px;
        }
      }
    }
  }
}

.bottom-inset-style {
  --safe-area-inset-bottom: env(safe-area-inset-bottom);
  height: calc(64px + var(--safe-area-inset-bottom));
}
.fill-vailable-mw{
  max-width: -webkit-fill-available
}
.magic-dots.slick-dots ul {
  margin: 4px 0;
}

.full-height {
  height: var(--window-height);
}
.avt-border-gradient {
  border: double 2px transparent;
  border-radius: 20px;
  background-origin: border-box;
  background-clip: content-box, border-box
}
